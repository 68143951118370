define("discourse/plugins/discourse-custom-wizard/discourse/initializers/custom-wizard-edits", ["exports", "discourse/lib/url", "discourse/lib/plugin-api", "discourse-common/lib/get-url", "discourse-common/utils/decorators"], function (_exports, _url, _pluginApi, _getUrl, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "custom-wizard-edits",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.custom_wizard_enabled) {
        return;
      }
      const existing = _url.default.routeTo;
      _url.default.routeTo = function (path, opts) {
        if (path && path.indexOf("/w/") > -1) {
          return window.location = path;
        }
        return existing.apply(this, [path, opts]);
      };
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        var _dec, _obj;
        api.modifyClass("component:d-navigation", {
          pluginId: "custom-wizard",
          actions: {
            clickCreateTopicButton() {
              let createTopicWizard = this.get("category.custom_fields.create_topic_wizard");
              if (createTopicWizard) {
                window.location.href = (0, _getUrl.default)(`/w/${createTopicWizard}`);
              } else {
                this._super();
              }
            }
          }
        });
        api.modifyClass("component:uppy-image-uploader", (_dec = (0, _decorators.observes)("id"), (_obj = {
          pluginId: "custom-wizard",
          initOnStepChange() {
            if (/wizard-field|wizard-step/.test(this.id)) {
              this._initialize();
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "initOnStepChange", [_dec], Object.getOwnPropertyDescriptor(_obj, "initOnStepChange"), _obj)), _obj)));
        api.modifyClass("component:d-editor", {
          pluginId: "custom-wizard",
          didInsertElement() {
            this._super(...arguments);
            if (this.wizardComposer) {
              this.appEvents.on(`wizard-editor:insert-text`, this, "_wizardInsertText");
              this.appEvents.on("wizard-editor:replace-text", this, "_wizardReplaceText");
            }
          },
          _wizardInsertText(text, options) {
            if (this.session.wizardEventFieldId === this.fieldId && this.element) {
              this.insertText(text, options);
            }
          },
          _wizardReplaceText(oldVal, newVal) {
            let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            if (this.session.wizardEventFieldId === this.fieldId) {
              this.replaceText(oldVal, newVal, opts);
            }
          }
        });
        api.modifyClass("component:category-chooser", {
          categoriesByScope() {
            let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            let categories = this._super(options);
            const currentUser = this.currentUser;
            if (!currentUser?.staff) {
              categories = categories.filter(category => {
                return !category.custom_fields?.create_topic_wizard;
              });
            }
            return categories;
          }
        });
      });
    }
  };
});