define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-logs", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("wizardId"), _dec2 = (0, _decorators.default)("wizardName"), _dec3 = (0, _decorators.default)("wizardId"), (_obj = {
    documentationUrl: "https://thepavilion.io/t/2818",
    wizardName(wizardId) {
      let currentWizard = this.wizardList.find(wizard => wizard.id === wizardId);
      if (currentWizard) {
        return currentWizard.name;
      }
    },
    messageOpts(wizardName) {
      return {
        wizardName
      };
    },
    messageKey(wizardId) {
      let key = "select";
      if (wizardId) {
        key = "viewing";
      }
      return key;
    }
  }, (_applyDecoratedDescriptor(_obj, "wizardName", [_dec], Object.getOwnPropertyDescriptor(_obj, "wizardName"), _obj), _applyDecoratedDescriptor(_obj, "messageOpts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "messageOpts"), _obj), _applyDecoratedDescriptor(_obj, "messageKey", [_dec3], Object.getOwnPropertyDescriptor(_obj, "messageKey"), _obj)), _obj)));
});